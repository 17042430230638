<template>
  <section>
    <div class="block">
    </div>
    <b-field label="Blocked">
    <b-switch v-model="model[field.key]">
            </b-switch>
    </b-field>
  </section>
</template>
<script>

export default {
  name: 'blocked',
  props: {
    baseModel: Object,
    field: Object,
    form: Object,
    model: Object,
    title: String,
    to: Object,
    value: String,
  },
  data() {
    return {
      roles: null,
      type: null,
      label: this.$props.field.templateOptions.wrapper.properties.label,
    };
  },
};
</script>
