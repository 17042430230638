<template>
  <input class="hidden" v-model="model[field.key]">
</template>

<script>
export default {
  props: {
    field: Object,
    model: Object,
  },
};
</script>

<style lang="scss" scoped>
  .hidden {
    display: none !important;
  }
</style>
